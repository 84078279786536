@tailwind base;
@tailwind components;
@tailwind utilities;

/* Loader start */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* This makes the container take the full viewport height */
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loader end */
